import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legaldocuments',
  templateUrl: './legaldocuments.component.html',
  styleUrls: ['./legaldocuments.component.css']
})
export class LegaldocumentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
