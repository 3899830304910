import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HashLocationStrategy, LocationStrategy} from '@angular/common';

import { AppComponent } from './app.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { CompanydetailComponent } from './components/companydetail/companydetail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNavComponent } from './components/homepage/main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule } from '@angular/material';

import {Routes, RouterModule} from '@angular/router';
import { WhysadikshyaComponent } from './components/whysadikshya/whysadikshya.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { FooterComponent } from './components/footer/footer.component';
import { LegaldocumentsComponent } from './components/legaldocuments/legaldocuments.component';
import { ProcedureflowchartComponent } from './components/procedureflowchart/procedureflowchart.component';

const appRoutes:Routes = [
  {path: '', component:HomepageComponent},
  {path: 'aboutus', component:CompanydetailComponent},
  {path: 'whysadikshya', component:WhysadikshyaComponent},
  {path: 'procedure', component:ProcedureflowchartComponent},
  {path: 'contactus', component:ContactusComponent},
  {path: 'legaldocuments', component:LegaldocumentsComponent}

]

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    CompanydetailComponent,
    MainNavComponent,
    WhysadikshyaComponent,
    ContactusComponent,
    FooterComponent,
    LegaldocumentsComponent,
    ProcedureflowchartComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    // AgmCoreModule.forRoot({
    //   apiKey: '';
    // })
    FormsModule,
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
