import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  lat: number = 27.676559;
  lng: number = 85.362286;
  constructor() { }

  ngOnInit() {
  }

}
