import { Component } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})
export class MainNavComponent {


  constructor() {}
  ngOnInit() {

  $(document).ready(function(){
    $(".menu-icon").on("click", function(){
      $("nav ul").toggleClass("showing");
    });
  });
  
  //Menu toggle on menu item click
  
  $(document).ready(function(){
    $("nav ul li").on("click", function(){
      $("nav ul").toggleClass("showing")
    });
  });
  
  
  //scrolling effect
  
    }
  }
